import React from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsBigForm from "../components/forms/TwoColContactUsWithIllustrationFullForm";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import useAnimatedNavToggler from "helpers/useAnimatedNavToggler.js";

import customerSupportIllustrationSrc from "images/plumbercartoon.svg";
import klantenImage from "images/plumberthree.jpg";
import KlantManImage from "images/plumberfour.jpg";
export default () => {
    const  { toggleNavbar } = useAnimatedNavToggler();
    return (
        <>
            <AnimationRevealPage onClick={toggleNavbar}>
                <Hero />
                <MainFeature />
                <Features />
                <MainFeature2 />
                <Testimonial
                    description="Ontdek wat onze gewaardeerde klanten zeggen over hun ervaringen met Loodgieter Holland. Bij ons staat klantcontact centraal. We streven ernaar om elke service tot een persoonlijke oplossing te maken. Leer meer over hoe ons toegewijde team een wereld van betrouwbaarheid en deskundigheid creëert voor onze gewaardeerde klanten."
                    testimonials={[
                        {
                            imageSrc:
                            klantenImage,
                            quote: "De service van Loodgieter Holland was uitstekend! Ze waren snel ter plaatse en hebben mijn probleem met het leidingwerk efficiënt opgelost. Betrouwbaar en professioneel vakmanschap, ik beveel ze ten zeerste aan.",
                            customerName: "Lotte Jansen"
                        },
                        {
                            imageSrc:
                            KlantManImage,
                            quote: "Ik was onder de indruk van de expertise van Loodgieter Holland. Ze hebben niet alleen het probleem opgelost, maar gaven ook waardevol advies om verdere problemen te voorkomen. Geweldige service en vriendelijk personeel!",
                            customerName: "Daan van Dijk"
                        }
                    ]}
                    textOnLeft={true}
                />
                <FAQ
                    imageSrc={customerSupportIllustrationSrc}
                    imageContain={true}
                />
                <ContactUsBigForm />
                <Footer />
            </AnimationRevealPage>
        </>
    )
};
